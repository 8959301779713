.fixed_header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 40em;
}
.fixed_header thead tr {
    width: 100%;
    display: block;
}
.fixed_header thead {
    background: black;
    color: #fff;
}

.fixed_header th,
.fixed_header td {
    padding: 5px;
    text-align: left;
    width: 140px;
}

input {
    width: 100%;
}
  
textarea {
    width: 100%;
}
  
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
